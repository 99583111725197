import Modal from 'react-bootstrap/Modal'
import { Headline2 } from '@/components/common/Headline2'
import { Button } from '@/components/controls/Button'
import { Input } from '@/components/controls/Input'
import { CrossBtn } from '@/components/controls/CrossBtn'
import { useForm, SubmitHandler } from 'react-hook-form'
import Link from 'next/link'
import { useQuery } from '@/utils/url'
import { useEffect, useRef, useState } from 'react'
import ReactCodeInput from 'react-verification-code-input'
import { VerificationCodeService } from '@/services/verification-code'
import { setPhoneIsVerified, setPhoneData } from '@/store/actions/checkout'
import { CountryPhoneCodeSelect } from '@/components/controls/CountryPhoneCodeSelect'
import { useDispatch, useSelector } from 'react-redux'
import { emitter } from '@/utils/emitter'
import { handleResponse } from '@/utils/http'
import { toast } from 'react-toastify'
import { RootState } from '@/store/reducers'
import Cookies from 'js-cookie'
import { getPhone } from '@/utils/functions'
import { PhoneControl } from '@/components/controls/PhoneControl'

interface Props {
  show: boolean,
  handleClose: () => void
}

type Inputs = {
  phone: string
}

type Inputs2 = {
  email: string
}

export const PhoneCaptureModal: React.FC<Props> = ({ show, handleClose }) => {
  const dispatch = useDispatch()

  const { register, handleSubmit, watch, formState } = useForm<Inputs>({
    mode: 'onBlur',
    defaultValues: {
      phone: ''
    }
  })

  const videoFunnelForm = useForm<Inputs2>({
    mode: 'onBlur',
    defaultValues: {
      email: ''
    }
  })

  const smsVerificationId = useRef('')
  const smsVerificationInput = useRef<ReactCodeInput>() as React.RefObject<ReactCodeInput>
  const [phone, setPhone] = useState('')
  const [country, setCountry] = useState({ value: 'US' })
  const phoneCompRef = useRef({ isValid: () => false, getPhone: () => {return {full: '', local: ''}} })
  // const [country, setCountry] = useState({ label: 'United States +1', value: 'US' })
  const [smsSent, setSmsSent] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [isProcessing, setProcessing] = useState(false)
  const clientLocationState = useSelector((state: RootState) => state.clientLocation)

  const privacyLink = useQuery('/privacy-policy')
  const termsLink = useQuery('/terms-of-service')

  const onSubmit: SubmitHandler<Inputs> = async () => {
    if (isProcessing) return

    setProcessing(true)

    if (!phoneCompRef.current.isValid()) {
      setProcessing(false)
      return
    }

    const phoneState = phoneCompRef.current.getPhone()
    setPhone(phoneState.local.replace(/\s/g,''))

    const res = await VerificationCodeService.create(phoneState.local.replace(/\s/g,''), country, setProcessing)

    handleResponse(res)
      .then((res: any) => {
        smsVerificationId.current = res.id
        setSmsSent(true)
      })
  }

  const onSubmitVideFunnel: SubmitHandler<Inputs2> = async (data) => {
    if (isProcessing) return

    setProcessing(true)

    emitter.emit('speak-with-coach-cta-email-submitted', {
      email: data.email.replace(/\s/g,'')
    })

    toast('Thank you! We’ll send you an email soon.')

    resetFrom()

    setTimeout(() => {
      setProcessing(false)
      handleClose()
    }, 600)
  }

  const resetFrom = () => {
    videoFunnelForm.reset({}, {
      keepDefaultValues: true
    })
  }

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        if (document.querySelectorAll<HTMLElement>('.modal')[0]) {
          document.querySelector('body')?.classList.remove('modal-open')
          document.querySelector('body')?.style.removeProperty('overflow')
          document.querySelectorAll<HTMLElement>('.modal-backdrop')[0].style.display = 'none'
          document.querySelectorAll<HTMLElement>('.modal')[0].style.display = 'none'
        }
      }, 600)
    } else {
      const backdropEl = document.querySelectorAll<HTMLElement>('.modal-backdrop')[0]

      if (backdropEl) {
        backdropEl.style.display = 'block'
      }
    }
  }, [show])

  useEffect(() => {
    if (clientLocationState.country_code) {
      setCountry({
        value: clientLocationState.country_code
      })
    }
  }, [clientLocationState])

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <CrossBtn id="modal-close-btn" className="modal__close-btn opacity-20" color="white" onClick={() => {
        handleClose()
      }}/>
      <div className="max-w-[400px] mx-auto sm:my-[20px] sm:mx-[15px] smaller:mx-auto md:my-[50px]">
        { !smsSent && <>
          <Modal.Header className="text-center max-w-[260px] sm2:max-w-none mx-auto">
            <Headline2 tag="h2" className="text-23-to-25 mb-[20px]">SPEAK WITH A COACH FOR FREE</Headline2>
            <p className="font--accent mb-[20px] md:mb-[40px] text-[11px] md:text-[14px]">Enter your best number to riff about your goals and productivity with a Commit Action coach. We’ll put you in touch over SMS first, and then they’ll send you a link to book a call too if you want.</p>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <PhoneControl
                label="Phone Number"
                country={country.value}
                value={phone}
                compRef={phoneCompRef}
                onCountryChange={(value: any) => {
                  if (!value) return
                  setCountry({ value: value })
                }}
                onValid={(value: any) => {
                  //
                }}
                onInvalid={() => {
                  //
                }}
              />
              <p className="text-[10px] mb-[22px] font--accent">* By submitting this form and signing up via text, you consent to being contacted by a Commit Action coach via SMS and to receive marketing text messages (such as promotion codes and cart reminders) from Commit Action Inc at the number provided, including messages sent by autodialer. Consent is not a condition of any purchase. Message and data rates may apply. Message frequency varies. You can unsubscribe at any time by replying STOP or clicking the unsubscribe link (where available) in one of our messages. View our <span className="link" onClick={() => { handleClose() }}><Link href={privacyLink}>Privacy Policy</Link></span> and <span className="link" onClick={() => { handleClose() }}><Link href={termsLink}>Terms of Service</Link></span></p>
              <Button type="primary" fullWidth={true} padding="sm">Submit</Button>
            </form>
          </Modal.Body>
        </>
        }
        { smsSent && !successMessage &&
        <div>
          <Headline2 tag="h2" color="white" className="text-[20px] mb-[6px]">Confirm your number</Headline2>
          <p className="mb-30-to-40 font--accent ">Enter the code we sent over SMS to {getPhone(country.value, phone)}</p>
          <div className="mb-30-to-40">
            <ReactCodeInput className="sms-code-input" type='number' ref={smsVerificationInput} fields={6} onComplete={async (value) => {
              if (isProcessing) return

              const res = await VerificationCodeService.compare(value, smsVerificationId.current, setProcessing)

              handleResponse(res)
                .then(() => {
                  dispatch(setPhoneIsVerified(true))
                  dispatch(setPhoneData({
                    phone: phone.replace(/\s/g,''),
                    phoneCountryCode: country.value
                  }))
                  setSuccessMessage(true)

                  emitter.emit('speak-with-coach-cta-phone-number-submitted', {
                    phone: phone.replace(/\s/g,''),
                    phoneCountryCode: country.value
                  })
                })
                .catch(() => {
                  smsVerificationInput.current && smsVerificationInput.current.__clearvalues__()
                })
            }} />
          </div>
          <p className="font--accent ">Didn’t get a code? <span className="link" onClick={async () => {
            if (isProcessing) return
            if (Cookies.get('sms-sent-again')) {
              toast.error('Sms already sent. You can ask again in minute.')
              return
            }

            const res = await VerificationCodeService.create(phone, country, setProcessing)

            handleResponse(res, 'Verification code resent')
              .then((res: any) => {
                emitter.emit('speak-with-coach-cta-ask-again-clicked', {
                  phone: phone.replace(/\s/g,''),
                  phoneCountryCode: country.value
                })

                smsVerificationId.current = res.id
              })
          }}>Ask again</span></p>
        </div>
        }
        { successMessage &&
          <div className="text-center">
            <Headline2 tag="h2" className="text-23-to-25 mb-[20px]">Success!</Headline2>
            <p className="mb-[20px] font--accent">One of our coaches should text you within a couple of minutes <br/>(p.s. we operate during US business hours).</p>
            <p className="mb-[20px] font--accent">While you wait, enter your email and we will send you our free video training on the science of productivity.</p>
            <form onSubmit={videoFunnelForm.handleSubmit(onSubmitVideFunnel)}>
              <Input
                placeholder="Your Email"
                dark={true}
                valid={!videoFunnelForm.formState.errors.email && videoFunnelForm.formState.touchedFields.email} error={videoFunnelForm.formState.errors.email?.message}
                {...videoFunnelForm.register('email', {
                  required: 'Email required',
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'This email is invalid'
                  }
                })}
              />
              <Button type="primary" fullWidth={true} padding="sm">Submit</Button>
            </form>
          </div>
        }
      </div>
    </Modal>
  )
}
